/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-08",
    versionChannel: "nightly",
    buildDate: "2024-06-08T00:05:40.726Z",
    commitHash: "6e53f980687cbc00ca5b505ff7a74df9a1a8c2a3",
};
